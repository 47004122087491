import api from "@/services/apiService"
import Encrypt from "@/helpers/encrypt"

class LottoService {

  createRound(data) {
    return new Promise((resolve, reject) => {
      // api.post(process.env.VUE_APP_BACKEND_API + 'lotto/round', {payload: Encrypt.encrypt(data)})
      api.post(process.env.VUE_APP_BACKEND_API + 'round', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getRoundByDate(date) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'round/' + date)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getRound(_id) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'round/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateRound(_id, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'round/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getRoundSettingNumbers(id) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'round/' + id + '/numbers')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  saveRoundSettingNumbers(id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'round/' + id + '/numbers', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  deleteRoundSettingNumbers(id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'round/' + id + '/numbers', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  cancelRound(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.delete(`${process.env.VUE_APP_BACKEND_API}round/${roundId}/${uniqueCode}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  cancelRounds(roundIds, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_BACKEND_API}round/cancels/${uniqueCode}`, {roundIds})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  saveResults(roundId, pos, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'round/' + roundId + '/result/' + pos, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateProcessingTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/processingTickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateProcessingItems(roundId, betType) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/processingItems/' + betType)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateWonItems(roundId, betType) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/wonItems/' + betType)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateLoseItems(roundId, betType) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/loseItems/' + betType)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateWonTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/wonTickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  updateLoseTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/loseTickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  saveLottosetResults(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'round/' + roundId + '/lottoset-result', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getWonTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'report/' + roundId + '/won')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  payWonMembers(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'report/' + roundId + '/payWonMember/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  findAgentWinLose(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'report/' + roundId + '/agentWinLose/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  payAgents(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'report/' + roundId + '/payAgent/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  closeTickets(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'report/' + roundId + '/closeTicket/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  closeItems(roundId, betType, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'report/' + roundId + '/closeItem/' + betType + '/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  closeRound(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'report/' + roundId + '/closeRound/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  changeResult(roundId, step, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API+'cancel/'+roundId+'/'+step+'/'+uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  changeResultTicket(roundId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET+'cancel/'+roundId+'/resetTicket/'+uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  changeResultItem(roundId, step, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET+'cancel/'+roundId+'/resetItem/'+step+'/'+uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getRoundWaitResults() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'round/wait-results')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  searchTickets(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'ticket/' + roundId + '/search', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getTicket(ticketId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'ticket/' + ticketId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  cancelTicket(ticketId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'ticket/' + ticketId + '/' + uniqueCode)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  refundTicket(ticketId, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'ticket/' + ticketId + '/' + uniqueCode + '/refund')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  searchNumbers(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'ticket/' + roundId + '/search-number', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  shareAmount(itemId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_TICKET + 'ticket/share-item/' + itemId, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getTicketQueue() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'ticket/queue')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  totalTicket(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/total-ticket')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  totalItem(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/total-item')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  deleteTickets(roundId) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/tickets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  deleteItems(roundId) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_API_TICKET + 'round/' + roundId + '/items')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  get500Tickets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'ticket/last500')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getForRefund(roundId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'ticket/forrefund/' + roundId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  getRecordingTickets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_TICKET + 'ticket/recording')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  refundBulkTicket(data, uniqueCode) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'ticket/bulk-refund/' + uniqueCode, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }
}

export default new LottoService();
